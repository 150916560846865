import { createStore } from 'vuex'

export default createStore({
  state: {
    api_token:'',
  },
  mutations: {
    AddToken(state,payload){
      state.api_token = payload.api_token
    },
  },
  actions: {
  },
  modules: {
  }
})

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import  "amfe-flexible";
import '@/assets/css/components.css'
import 'vant/es/toast/style';
import 'vant/es/notify/style';
import { NavBar,ImagePreview,Field, CellGroup,Cell,Search, Form,Toast} from 'vant';

const app=createApp(App)
app.use(store)
app.use(NavBar)
app.use(ImagePreview)
app.use(Field)
app.use(CellGroup)
app.use(Cell)
app.use(Toast)
app.use(Search)
app.use(Form)
app.use(router).mount('#app')
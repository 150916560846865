import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'brand',
    },
  },
  {
    name: 'brand',
    path: '/brand',
    component: () => import('@/views/brand'),
    meta: {
      title: '品牌列表',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      title: '账号验证',
    },
  },
  {
    name: 'store',
    path: '/store',
    component: () => import('@/views/store'),
    meta: {
      title: '门店信息',
    },
  },
  {
    name: 'success',
    path: '/success',
    component: () => import('@/views/success'),
    meta: {
      title: '创建成功',
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('@/views/user'),
    meta: {
      title: '会员中心',
    },
  },
  {
    name: 'cart',
    path: '/cart',
    component: () => import('@/views/cart'),
    meta: {
      title: '购物车',
    },
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('@/views/goods'),
    meta: {
      title: '商品详情',
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});


export default router
